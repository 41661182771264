<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Authors Table -->
		<a-row :gutter="24" type="flex">  
			<a-col :span="6" class="mb-6">
                <div>
                    <div class="dashboard-bar-line ant-card" style="padding-top: 10px;">
                        <a-steps direction="vertical" :current="activeStep">
                            <a-step title="مشاوره" description="مشاوره رایگان." />
                            <a-step title="توضیحات" description="انتخاب و توضیح تکنولوژی های موردنیاز." />
                            <a-step title="امکانات" description="ثبت امکانات مورد نیاز و توضیحات نهایی" />
                            <a-step title="بررسی" description="بررسی نهایی و قیمت گذاری." />
                            <a-step title="پیش پرداخت" description="پیش پرداخت و آغاز انجام پروژه." />
                            <a-step title="بررسی پیشرفت" description="نمایش پروسه پیشرفت پروژه." />
                        </a-steps>
                    </div>
                </div>
			</a-col>
			<a-col :span="18" class="mb-18">
                <a-form  :form="form">
                <a-card :class="{ hide: !(activeStep == 0) }" :bordered="false" class="card-info">

                    <div class="card-content">
                        <h6>مشاوره</h6>
                        <a-divider />
                        <div>
                            توضیحات مشاوره.
                        </div>
                    </div>
                    
                    <div class="card-footer ltr">
                        <a-button @click="goTo(1)" type="primary">
                            ادامه
                        </a-button>
                    </div>
                </a-card>
                <a-card :class="{ hide: !(activeStep == 1) }" :bordered="false" class="card-info">
                    
                    <a-row type="flex">
                        <a-col class="col-content" :span="24" :xl="12">
                            <div class="card-content">
                                <h6>توضیحات پروژه خود را ثبت کنید</h6>
                                <a-divider />

                                    <a-form-item
                                        :label-col="formItemLayout.labelCol"
                                        :wrapper-col="formItemLayout.wrapperCol"
                                        label="نام پروژه"
                                    >
                                        <a-input
                                        v-decorator="[
                                            'name_fa',
                                            {
                                                rules: [{ required: true, message: 'لطفا نام پروژه خود را به فارسی بنویسید' }]
                                            },
                                            
                                        ]"

                                        placeholder="بنویسید"
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        :label-col="formItemLayout.labelCol"
                                        :wrapper-col="formItemLayout.wrapperCol"
                                        label="نام پروژه به انگلیسی"
                                    >
                                        <a-input
                                        v-decorator="[
                                            'name',
                                            {
                                                rules: [{ required: true, message: 'لطفا نام پروژه خود را به انگلیسی بنویسید' }]
                                            },
                                            
                                        ]"

                                        placeholder="بنویسید"
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        :label-col="formItemLayout.labelCol"
                                        :wrapper-col="formItemLayout.wrapperCol"
                                        label="تکنولوژی های مورد استفاده در محیط وب " v-if="Object.values(Project.platforms).includes('web')">
                                        <b>انتخاب فرمورک سمت کاربر (Front-End)</b>
                                        <a-radio-group>
                                            <a-radio-button value="semantic">
                                            Semantic UI/jQuery
                                            </a-radio-button>
                                            <a-radio-button value="boostrap">
                                            Bootstrap/jQuery
                                            </a-radio-button>
                                            <a-radio-button value="vuejs">
                                                VueJS
                                            </a-radio-button>
                                            <a-radio-button value="react">
                                                ReactJS
                                            </a-radio-button>
                                            <a-radio-button value="private">
                                                طراحی اختصاصی
                                            </a-radio-button>
                                        </a-radio-group>
                                        <b>انتخاب فرمورک سمت سرور (Back-End)</b> -
                                        <a-alert message=" در حال حاظر تمام پروژه ها با زبان PHP طراحی میشوند!" type="info" show-icon />
                                        <a-radio-group>
                                            <a-radio-button value="codeigniter">
                                                CodeIgniter
                                            </a-radio-button>
                                            <a-radio-button value="laravel">
                                                Laravel
                                            </a-radio-button>
                                            <a-radio-button value="symfony">
                                                Symfony
                                            </a-radio-button>
                                            <a-radio-button value="private">
                                                طراحی اختصاصی
                                            </a-radio-button>
                                        </a-radio-group>
                                        <a-divider/>
                                    </a-form-item>
                                    <a-form-item
                                        :label-col="formItemLayout.labelCol"
                                        :wrapper-col="formItemLayout.wrapperCol"
                                        label="پلتفرم های مورد پشتیبانی "
                                    >
                                        <a-switch value="web" @change="selectPlatform" default-checked /> Web (وبسایت)
                                        <a-divider/>
                                        <a-switch value="tgbot" @change="selectPlatform" /> Telegram Bot (ربات تلگرام)
                                        <a-divider/>
                                        <a-switch value="android" @change="selectPlatform" /> Android (نرم افزار اندروید)
                                        <a-divider/>
                                        <a-switch  @change="selectPlatform" disabled /> iOS (نرم افزار گوشی های اپل)
                                        <a-divider/>
                                        <a-switch value="windows"  @change="selectPlatform" /> Windows (نرم افزار ویندوز)
                                        <a-divider/>
                                        <a-switch value="linux"  @change="selectPlatform" /> Linux (نرم افزار لینوکس)
                                        <a-divider/>
                                        <a-switch  @change="selectPlatform" disabled /> MacOS (نرم افزار سیستم های مک)
                                        <a-divider/>
                                    </a-form-item>
                            </div>
                            <div class="card-footer ltr">
                                <a-button @click="saveDescriptions" type="primary">
                                    ادامه
                                </a-button>
                            </div>
                        </a-col>
                    </a-row>
                </a-card>
                <a-card :class="{ hide: !(activeStep == 2) }" :bordered="false" class="card-info">
                    <div class="card-content">
			    		<h6>ثبت امکانات {{this.form.getFieldValue('name_fa')}} ({{this.form.getFieldValue('name')}})</h6>
                        <a-divider />
                        <a-alert
                            message="توجه"
                            description="درخواست امکانات ممکن است برای افراد کمی گمراه کننده باشد، ما سعی کردیم با طراحی فرم زیر این پروسه را کمی بهبود ببخشیم. "
                            type="info"
                        />
                    </div>
                    <br/>
                    <div>
                        <a-collapse default-active-key="55">
                            <a-collapse-panel key="1" header="سیستم کاربری">
                                <p>مدیریت کاربری، ثبت نام، ورود، بازیابی.</p>
                                <p>
                                    <a-switch v-model="Project.requests.userManagement" /> درخواست سیستم مدیریت کاربری
                                </p>
                                <p>
                                    <a-switch v-model="Project.requests.userGroups" /> قابلیت گروه بندی کاربران
                                </p>
                            </a-collapse-panel>
                            <a-collapse-panel key="4" header="سیستم مدیریت محتوا (اخبار،بلاگ)">
                                <p>امکانات درج، ویرایش و مدیریت محوتا، میتوان از این بخش به عنوان اخبار و یا حتی یک وبلاگ اختصاصی استفاده کنید.</p>
                                <a-switch v-model="Project.requests.contentManagement" /> درخواست سیستم مدیریت محتوا
                            </a-collapse-panel>
                            <a-collapse-panel key="3" header="فروشگاه">
                                سیستم مدیریت فروشگاه، قابلیت درج،حذف و مدیریت محصولات.
                                <a-switch v-model="Project.requests.shopManagment" />
                            </a-collapse-panel>
                            
                        </a-collapse>
                    </div>
                    <br/>
                    <div class="card-footer ltr">
                        <a-button @click="saveProject" type="primary">
                            ادامه
                        </a-button>
                        <a-divider type="vertical"/>
                        <a-button @click="goTo(1)" type="dashed danger ">
                            بازگشت
                        </a-button>
                    </div>
                </a-card>
                </a-form>
			</a-col>
                
		</a-row>
		<!-- / Authors Table -->

		<!-- Authors Table -->
		<a-row :gutter="5" type="flex">  
		</a-row>
		<!-- / Authors Table -->

	</div>
</template>

<script>
Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};
	import Vue from 'vue'
	import { userService } from '../../services/user.service';
    
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
	export default ({
		components: {

        },
        created(){

			this.user = Vue.prototype.$me;
        },
        methods: {
            saveProject(){
                var that = this;
                var Data = {
                    name: this.form.getFieldValue('name'),
                    name_fa: this.form.getFieldValue('name_fa'),
                    platforms: this.Project.platforms,
                    requests: this.Project.requests,
                }

                // load user list
                userService.addProject(Data).then(response => {   
                    console.log(response); 
                        console.log(response.status + "AAAA");
                    if (response.status == 200) {
                        return response.json();            
                    } else {
                    }
                }).then((response) => {

                    console.log(response.status);
                    switch (response.status) {
                        case 500:
                            if(typeof response.message === 'object' && response.message !== null)
                            {
                                Object.keys(response.message).forEach(function(key) {
                                    that.$message.error(response.message[key]);
                                    console.log(key, response.message[key]);
                                });
                            }
                            break;
                    
                        default:
                            break;
                    }
                }).catch((e) => {
                    console.log(e);
                });
            },
            saveDescriptions(){

                this.form.validateFields(err => {
                    if(this.Project.platforms.length == 0)
                    {
                        this.$message.info('لطفا یک پلتفرم انتخاب کنید');
                    }
                    else
                    {
                        if(!err)
                        {
                            this.activeStep = 2;
                        }
                        else
                        {
                            this.$message.info('توضیحات پروژه خود بصورت کامل وارد کنید.');
                        }
                    }
                })
            },
            goTo(index){
                this.activeStep = index;
            },
            selectPlatform(checked,element){
                if(checked)
                {
                    if(!Object.values(this.Project.platforms).includes(element.target.getAttribute('value')))
                    {
                        this.Project.platforms.push(element.target.getAttribute('value'))
                    }
                }
                else
                {

                    if(Object.values(this.Project.platforms).includes(element.target.getAttribute('value')))
                    {
                        this.Project.platforms.remove(element.target.getAttribute('value'))
                    }
                }
                console.log(this.Project.platforms)
            },
            next() {
                this.current++;
            },
            prev() {
                this.current--;
            },
        },
		data() {
			return {
                Project: {

                    platforms: [
                        'web'
                    ],
                    requests:{
                        userManagement: false,
                        userGroups: false,
                        contentManagement: false,
                        shopManagment: false,
                    },
                },
                formItemLayout,
                formTailLayout,
                form: this.$form.createForm(this, { 
                    name: 'dynamic_rule' 
                }),
                activeStep: 0,
                steps: [
                    {
                    title: 'First',
                    content: 'First-content',
                    },
                    {
                    title: 'Second',
                    content: 'Second-content',
                    },
                    {
                    title: 'Last',
                    content: 'Last-content',
                    },
                ],
                user: null,
				
			}
		},
	})

</script>

<style lang="scss">
</style>