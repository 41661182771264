import {config} from './config';

export const userService = {
    getUsers,
    addMeta,
    saveUser,
    addProject,
    getProject,
    getProjects,
    sendActivationMail
}


function sendActivationMail(user) {

    const data = {
        login: user,
    }
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/user/authentication/send_activate_mail`, requestOptions)
}
function addMeta(metakey, metavalue, preload) {
    const token = localStorage['token'];

    const data = {
        meta_key: metakey,
        meta_value: metavalue,
        preload: preload
    }
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+token,
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/user/add_meta`, requestOptions)
}
function getUsers() {
    const token = localStorage['token'];

    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Bearer "+token,
        }
    };

    return fetch(`${config.apiUrl}/user/list`, requestOptions)
}

// projects has to have its own api service
function addProject(project) {
    const token = localStorage['token'];

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+token,
        },
        body: JSON.stringify(project)
    };

    return fetch(`${config.apiUrl}/projects/add`, requestOptions)
}

// projects has to have its own api service
function getProject() {
    const token = localStorage['token'];

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+token,
        },
        //body: JSON.stringify(project)
    };

    return fetch(`${config.apiUrl}/projects/get`, requestOptions)
}

// projects has to have its own api service
function getProjects() {
    const token = localStorage['token'];

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+token,
        },
        //body: JSON.stringify(project)
    };

    return fetch(`${config.apiUrl}/projects/list`, requestOptions)
}

// WOW RED ALRET! ADMIN API ???? has to go into adminApiServices.js
function saveUser(user) {
    const token = localStorage['token'];

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+token,
        },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/user/save`, requestOptions)
}